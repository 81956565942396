import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
 

export function PasswordReset() {
    const { token } = useParams<{ token: string }>();
    const [newPassword, setNewPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const navigate = useNavigate();

    const onSubmit = () => {
        setErrorMessage('');
        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        const resetEndpoint = '/api/confirm-reset';
        const formData = new URLSearchParams();
        if (token) {
            formData.append('token', token);
        }       
        formData.append('password', newPassword);

        fetch(resetEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to reset password');
                }
                alert('Password has been reset successfully');
                navigate('/');
            })
            .catch(err => {
                setErrorMessage(err.message);
            });
    };

    return (
        <section id="reset-password">
            <h2>Reset Password</h2>
            <div>
                <label htmlFor="newPassword">New Password: </label>
                <input id="newPassword" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <div>
                <label htmlFor="confirmPassword">Type Again Password: </label>
                <input id="confirmPassword" 
                       type="password"
                       value={confirmPassword}
                       onChange={(e) => setConfirmPassword(e.target.value)} 
                />
            </div>
            <button onClick={onSubmit}>Reset Password</button>
            <p className="error-message">{errorMessage}</p>
        </section>
    );
}
