/* Internal types */

export enum SectionTypes {
    Search,
    ConfirmAddress,
    PropertyDetails,
    Analytics
}

export interface SearchPropertyDetails {
    typeOfResidence: string;
    nBedrooms: string;
    nBathrooms: string;
    nCarspaces: string;
    maxDistance: string;
    nMonths: string;
}

/* React prop types */

export interface LoginProps {
    completeLogin: (email: string) => void;
}

export interface LogoutProps {
    emailLogin: string;
    completeLogout: () => void;
}

export interface SearchProps {
    notifyComplete: (address: Address) => void;
    completeLogout: () => void;
    emailLogin: string;
    forcedSearch: string;
}

export interface ConfirmAddressProps {
    address: Address;
    complete: boolean;
    notifyComplete: () => void;
}

export interface AddressDetailsProps {
    address: Address;
}

export interface PropertyDetailsProps {
    address: Address;
    complete: boolean;
    notifyComplete: (propertyDetails: SearchPropertyDetails) => void;
}

export interface AnalyticsProps {
    address: Address;
    propertyDetails: SearchPropertyDetails;
    refresh: boolean;
    notifyComplete: () => void;
    doForcedSearch: (address: string) => void;
}

export interface MarketAveragesProps {
    errorMessage: string;
    propertyDetails: SearchPropertyDetails;
    averages: SuburbAverages;
}

export interface RankingsProps {
    address: Address;
    errorMessage: string;
    rankings: CorelogicData;
    averages: SuburbAverages;
    doForcedSearch: (address: string) => void;
}

export interface AgentRankingsProps {
    rankings: CorelogicAgentData[];
    averages: SuburbAverages;
    nameAction: (name: string, type: SortableTableType) => void;
}

export interface AgencyRankingsProps {
    rankings: CorelogicAgencyData[];
    averages: SuburbAverages;
    nameAction: (name: string) => void;
}

export interface AgentTransactionHistoryProps {
    name: string;
    address: Address;
    transactionDetails: CorelogicTransactionData[];
    doForcedSearch: (address: string) => void;
}

export interface AgencyTransactionHistoryProps {
    name: string;
    transactionDetails: CorelogicTransactionData[];
    doForcedSearch: (address: string) => void;
    nameAction: (name: string) => void;
}

// Sortable table definitions

export enum SortableTableType {
    Agent,
    Agency
}

export interface SortableTableProps<T> {
    headers: SortableTableHeader<T>[];
    data: SortableTableData<T>[];

    // rowActionFn?: (row: T) => void; // TODO(Vivek): get rid of?
    // rowClassFn?: (row: T) => string;
}

export interface SortableTableHeader<T> {
    name: string; // WARNING: must be distinct!
    extractorFn: (row: T) => any;

    sortFn?: (a: T, b: T) => boolean; // true if a >= b
    actionFn?: (row: T) => void;
    classFn?: (row: T) => string;
    colSpan?: number;
}

export interface SortableTableData<T> {
    row: T;

    rowClassFn?: (row: T) => string;
    cancelHeaderActions?: boolean;
    overrideValues?: { [headerName: string]: any };
    // rowActionFn?: (row: T) => void; // TODO(Vivek): get rid of?
    // rowClassFn?: (row: T) => string;
}

/* API types */

export interface Address {
    address: string;
    localityId: string;
    attributes: {
		PParea: string;
        agency: string;
        agent: string;
        bathrooms: string;
        bedrooms: string;
        carSpaces: string;
        days_on_market: string;
        landArea: string;
		floorArea: string;
        last_sold: string;
        list_price: string;
        list_price_from: string;
        list_price_to: string;
        property_type: string;
        sale_price: string;
        state: string;
    };
}

export interface SuburbAverages {
    last_ad_price: number;
    days_on_market: number;
    price_SQM: number;
	PParea: number;
    sale_price: number;
	list_2_sale_diff: number;
    sold_properties: number;
    sold_properties_per_agency: number;
    sold_properties_per_agent: number;
    top_agency: string;
    avg_land_area: number;
	ratio_withdrawn: number;
}

export interface CorelogicData {
    'agent_rankings': CorelogicAgentData[];
    'agency_rankings': CorelogicAgencyData[];
    'transaction_details': CorelogicTransactionData[];
}

export interface CorelogicAgentData {
    agent: string;
    top_agency: string;
    days_on_market: number;
    last_ad_price: number;
    sale_price: number;
	list_2_sale_diff: number;
    price_SQM: number;
    PParea: number;
    sold_properties: number;
    sold_properties_per_agency: number;
    sold_properties_per_agent: number;
    avg_land_area: number;
    rank: number;
	// TODO(Vivek): number in backend implementation, but converting to string makes our life easier
    score: string; 
	ratio_withdrawn: number;
}

export interface CorelogicAgencyData {
    agency: string;
    days_on_market: number;
    last_ad_price: number;
    sale_price: number;
	price_SQM: number;
    PParea: number;
    sold_properties: number;
    sold_properties_per_agency: number;
    sold_properties_per_agent: number;
    avg_land_area: number;
	rank: number;
	score: string; // number in backend implementation, but converting to string makes our life easier
	ratio_withdrawn : number;
}

export interface CorelogicTransactionData {
    agent: string;
    agency: string;
    address: string;
    bathrooms: number;
    bedrooms: number;
    car_spaces: number;
    days_on_market: number;
    first_list_price_from: number;
    first_list_price_to: number;
    last_list_price_from: number;
    last_list_price_to: number;
    list_price: number;
    list_price_from: number;
    list_price_to: number;
    sale_price: number;
    land_area: number;
	price_SQM: number;
	PParea: number;
    property_type: string;
    date: string;
}

export interface DomainAgentTransactionsData {
    agent: {
        agencyName: string;
        agentId: number;
        name: string;
        profileUrl: string;
        state: string;
        suburb: string;
        thumbnail: string;
    };
    transactions: DomainAgentTransaction[];
	ratio_withdrawn: number;
}

export interface DomainAgentTransaction {
    address: string;
    agency: string;
    bathrooms: number;
    bedrooms: number;
    car_spaces: number;
    date_listed: string;
    days_on_market: number;
    first_ad_agency: string;
    first_ad_date: string;
    first_ad_price: number;
    last_ad_agency: string;
    last_ad_date: string;
    last_ad_price: number;
    land_area: number;
    price_listed: string;
    sale_price: number;
    sold_date: string;
}


/* Config types */

/**
 * The AppStrat interface declares operations common to all supported versions of the app.
 *
 * The App component uses this interface to call the function adapted to the current provider.
 */
export interface AppStrat {
	// These are always in meters, and should be purely numeric: e.g. "100"
	searchRadiiValues:  string[];
	// These are expressed in the units expected by the user
	// e.g. "100m"
	searchRadiiLabels:  string[];
	AREA_UNIT_ELE: any; // really has an element type but I don't know how to write this
	AREA_UNIT_STR: string; // really has an element type but I don't know how to write this
}
