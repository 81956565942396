import React, { useState } from 'react';
import { SortableTableProps, SortableTableHeader, SortableTableData } from '../types';

export default function SortableTable<T>({ headers, data }: SortableTableProps<T>) {
    const initialSortedHeader = headers.some(header => header.sortFn)
        ? headers.find(header => header.sortFn)
        : headers[0];

    const [sortedHeader, setSortedHeader] = useState((initialSortedHeader as SortableTableHeader<T>));
    const [sortDirection, setSortDirection] = useState(1);

    const sortFn = (a: SortableTableData<T>, b: SortableTableData<T>) => {
        if (sortedHeader.sortFn) {
            return sortedHeader.sortFn(a.row, b.row) ? -sortDirection : sortDirection;
        } else {
            return 0; // undefined case
        }
    };

    const toggleSort = (header: SortableTableHeader<T>) => {
        if (header.name === sortedHeader.name) {
            setSortDirection(-sortDirection);
        } else {
            setSortedHeader(header);
            setSortDirection(1);
        }
    };

    const sortableHeaderClassname = (header: SortableTableHeader<T>) => {
        return header.name === sortedHeader.name
            ? 'clickable ' + (sortDirection === 1 ? 'desc' : 'asc')
            : 'clickable ';
    };

    const sortableCellClassname = (header: SortableTableHeader<T>, data: SortableTableData<T>) => {
        let className = header.actionFn && !data.cancelHeaderActions ? 'clickable ' : '';

        if (header.classFn) {
            className += header.classFn(data.row);
        }

        return className;
    };

    const sortableCellAction = (header: SortableTableHeader<T>, data: SortableTableData<T>) => {
        if (header.actionFn && !data.cancelHeaderActions) {
            header.actionFn(data.row);
        }
    };

    return (
        <table className="big-table">
            <thead>
                <tr>
                    {headers.map((header) => {
                        return (
                            <th
                                scope="col"
                                colSpan={header.colSpan}
                                className={header.sortFn && sortableHeaderClassname(header)}
                                onClick={header.sortFn && (() => toggleSort(header))}
                            >
                                {header.name}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {data.sort(sortFn).map((data) => {
                    return (
                        <tr className={data.rowClassFn && data.rowClassFn(data.row)}>
                            {headers.map((header) => {
                                return (
                                    <td
                                        colSpan={header.colSpan}
                                        onClick={() => sortableCellAction(header, data)}
                                        className={sortableCellClassname(header, data)}
                                    >
                                        {(data.overrideValues && header.name in data.overrideValues)
                                            ? data.overrideValues[header.name]
                                            : header.extractorFn(data.row)}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table >
    );
}
