import React, { useEffect, useRef, useState } from 'react';
import logo_small from '../assets/images/real_estate_btn_zoom.png';
import { ConfirmAddressProps } from '../types';

declare var google: any; // should have been loaded in App.tsx

export default function ConfirmAddress({ complete, address, notifyComplete }: ConfirmAddressProps) {
    const mapRef = useRef<HTMLDivElement>(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        showAddress(address.address);
    }, [address]);

	function showAddress(address: string) {
		setErrorMessage('');
		const mapElement = document.querySelector('#map');
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode( {'address': address}, 
			function(results : Array<any>, status : string) {
				if (status != 'OK') {
					throw new Error('Fetch request failed with status : ' + status);
				}
				var latlng = new google.maps.LatLng(-34.397, 150.644);
				const coords = { 
					lat: results[0].geometry.location.lat,
					lng: results[0].geometry.location.lng 
				};
				const map = new google.maps.Map(mapElement, {
					zoom: 17, // Empirically determined
					center: results[0].geometry.location
				});
				const marker = new google.maps.Marker({
					position: results[0].geometry.location,
					map,
					icon: logo_small
				});
	    })
	    .catch((err:any) => {
		mapRef.current?.firstChild?.remove();
		setErrorMessage('Could not load map : ' + err.message);
	    });
	}

	// not used anymore, but kept for reference
    function showAddress_old(address: string) {
        setErrorMessage('');
        const resolveCoordsEndpoint = `/api/gmaps-coords?address=${address}`;
        const mapElement = document.querySelector('#map');

        fetch(resolveCoordsEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch request failed with status : ' + response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if (json.error) {
					throw new Error('JSON error -> ' + json.error + ' // ' + resolveCoordsEndpoint);
                } else {
                    const coords = { lat: json.geometry.location.lat, lng: json.geometry.location.lng };
                    const map = new google.maps.Map(mapElement, {
                        zoom: 17, // Empirically determined
                        center: coords
                    });
                    const marker = new google.maps.Marker({
                        position: coords,
                        map,
                        icon: logo_small
                    });
                }
            })
            .catch(err => {
                mapRef.current?.firstChild?.remove();
                setErrorMessage('Could not load map : ' + err.message);
            });
    }

    return (
        <section>
            <h2>Confirm Address</h2>
            <div id="map" ref={mapRef}></div>
            <p className="error-message">{errorMessage}</p>
            <p>The address you entered has been recognized</p>
            <p className="margined text-highlight">{address.address}</p>
            {!complete && <button onClick={notifyComplete}>Confirm</button>}
        </section>
    );
}
