import React from 'react';
import { appConfig } from '../uiconfig'
import { MarketAveragesProps } from '../types';
import { fmt_num, fmt_capitalization, fmt_diff_style, fmt_sale_list_price_diff } from '../utils';

export default function MarketAverages({ propertyDetails, averages, errorMessage }: MarketAveragesProps) {
    return (
        <section>
            <h2>Current Market Averages</h2>
            {errorMessage
                ? <p className="error-message">{errorMessage}</p>
                : <React.Fragment>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Property type</th>
                                <th scope="col"># Bedrooms</th>
                                <th scope="col"># Bathrooms</th>
                                <th scope="col"># Car Spaces</th>
                                <th scope="col">Search Radius</th>
                                <th scope="col">Timeline</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{fmt_capitalization(propertyDetails.typeOfResidence)}</td>
                                <td>{propertyDetails.nBedrooms}</td>
                                <td>{propertyDetails.nBathrooms}</td>
                                <td>{propertyDetails.nCarspaces}</td>
                                <td>{propertyDetails.maxDistance}</td>
                                <td>{propertyDetails.nMonths}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Average Sale Price</th>
                                <th scope="col">Average List / Sale Price Differential</th>
                                <th scope="col">Average Land Size</th>
                                <th scope="col">Average Price Per {appConfig.AREA_UNIT_STR}</th>
                                <th scope="col">Average Days on Market</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>$ {fmt_num(averages.sale_price)}</td>
                                <td className={fmt_diff_style(averages.sale_price, averages.last_ad_price)}>
                                    $ {fmt_num(fmt_sale_list_price_diff(averages.sale_price, averages.last_ad_price))}
                                </td>
                                <td>{averages.avg_land_area} {appConfig.AREA_UNIT_ELE}</td>
                                <td>$ {fmt_num(averages.PParea)}</td>
                                <td>{averages.days_on_market} days</td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            }
        </section>
    );
}
