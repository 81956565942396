//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--
// Configuration file for Australian version of the app.
//
//				|\\._
//              |   66__
//               \    _.P
//           ,    `) (
//           )\   / __\__
//          / /  / -._);_)
//         |  `\/  \ __|\
//          \  ;    )  / )
//           `\|   /__/ /__   
//             `\______)___)
//
//
// In order to use this file, put it in the src directory under the name 'uiconfig.ts'.
// The file 'uiconfig.ts' should not be commited to git so that different servers can
// maintain different confs.
//
//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--//--

import React from 'react';
import { AppStrat } from './types'


// TODO(Manu): Move all the constants inside this interface
export const appConfig: AppStrat = {
	searchRadiiValues: ['100', '250', '500', '750', '1000', '1500', '5000', '10000'],
	searchRadiiLabels: ['100m', '250m', '500m', '750m', '1km', '1.5km', '5km', '10km'],
	AREA_UNIT_ELE: <span>m<sup>2</sup></span>,
	AREA_UNIT_STR: 'SQM'
};

export const GMAPS_COUNTRY :string = 'au'
export const HAS_PROVIDER_2 :boolean = true

// maps UI prop types to backend types.
export const typeOfResidenceValues: { commercial: { [id: string]: string }, residential: { [id: string]: string } } = {
	commercial: {
		'retail': 'COMMERCIAL',
		'office': 'BUSINESS',
		'industrial_and_warehouse': 'STORAGE_UNIT',
		'showroom_and_bulkygoods': 'COMMERCIAL',
		'land_and_developments': 'LAND',
		'hotel_and_leisure': 'COMMERCIAL',
		'medical_and_consulting': 'OTHER',
		'commercial_farming': 'FARM',
		'other': 'OTHER'
	},
	residential: {
		'house': 'HOUSE',
		'unit': 'UNIT',
		'townhouse': 'HOUSE',
		'land': 'LAND',
		'acreage': 'LAND',
		'rural': 'FARM',
		'block_of_units': 'FLATS',
		'villa': 'HOUSE',
		'retirement': 'COMMUNITY',
		'other': 'OTHER',
	}
};

// maps back-end property types to UI property types
export const typesToResidenceValues: { commercial: { [id: string]: string }, residential: { [id: string]: string } } = {
	commercial: {
		  'BUSINESS': "office",
		  'COMMERCIAL': "retail",
		  'FARM': "commercial_farming",
		  'LAND': "land_and_developments",
		  'OTHER': "other",
		  'STORAGE_UNIT': "industrial_and_warehouse"
	},
	residential: {
		'COMMUNITY': "retirement",
		'FARM': "rural",
		'FLATS': "block_of_units",
		'HOUSE': "house",
		'LAND': "acreage",
		'OTHER': "other",
		'UNIT': "unit"
	}
};
