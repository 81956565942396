import React, { useState, useRef, useEffect } from 'react';
import { appConfig } from '../uiconfig';
import {
    fmt_num,
    fmt_capitalization,
    fmt_price_per_sqm,
    fmt_sale_list_price_diff,
    fmt_diff_style,
    fix_cmp,
} from '../utils';
import {
    CorelogicAgentData,
    CorelogicAgencyData,
    CorelogicTransactionData,
    DomainAgentTransaction,
    DomainAgentTransactionsData,
    RankingsProps,
    SortableTableHeader,
    SortableTableData,
    SortableTableType,
    AgentRankingsProps,
    AgencyRankingsProps,
    AgencyTransactionHistoryProps,
    AgentTransactionHistoryProps
} from '../types';
import SortableTable from './SortableTable';
import { Loader } from './Loader';

export default function Rankings({ address, errorMessage, rankings, averages, doForcedSearch }: RankingsProps) {
    const [tab, setTab] = useState(SortableTableType.Agent);
    const [selectedTxHistory, setSelectedTxHistory] = useState({ type: SortableTableType.Agent, name: '' });

    return (
        <React.Fragment>
            <section>
                <h2>Agent/Agency Rankings</h2>
                <div id="rankings-tabs">
                    <button
                        className={tab === SortableTableType.Agent ? "tab active" : "tab"}
                        onClick={() => {
                            setTab(SortableTableType.Agent);
                        }}>
                        Agent Rankings
		</button>
                    <button
                        className={tab === SortableTableType.Agency ? "tab active" : "tab"}
                        onClick={() => {
                            setTab(SortableTableType.Agency);
                        }}>
                        Agency Rankings
		</button>
                </div>
                {errorMessage
                    ? <p className="error-message">{errorMessage}</p>
                    : tab === SortableTableType.Agent
                        ? <AgentRankings
                            averages={averages}
                            rankings={rankings.agent_rankings}
                            nameAction={
                                (name: string, type: SortableTableType) => setSelectedTxHistory({ type, name })
                            }
                        />
                        : <AgencyRankings
                            averages={averages}
                            rankings={rankings.agency_rankings}
                            nameAction={
                                (name: string) => setSelectedTxHistory({ type: SortableTableType.Agency, name })
                            }
                        />
                }
            </section>
            {selectedTxHistory.type === SortableTableType.Agent && selectedTxHistory.name &&
                <AgentTransactionHistory
                    address={address}
                    name={selectedTxHistory.name}
                    transactionDetails={rankings.transaction_details}
                    doForcedSearch={doForcedSearch}
                />
            }
            {selectedTxHistory.type === SortableTableType.Agency && selectedTxHistory.name &&
                <AgencyTransactionHistory
                    name={selectedTxHistory.name}
                    transactionDetails={rankings.transaction_details}
                    doForcedSearch={doForcedSearch}
                    nameAction={(name: string) => setSelectedTxHistory({ type: SortableTableType.Agent, name })}
                />
            }
        </React.Fragment>
    );
}

function AgentRankings({ averages, rankings, nameAction }: AgentRankingsProps) {
    const headers: SortableTableHeader<CorelogicAgentData>[] = [
        {
            name: 'Index',
            extractorFn: (row) => row.score,
            sortFn: (a, b) => fix_cmp(a.score, b.score, '-'),
        },
        {
            name: 'Agent',
            extractorFn: (row) => row.agent,
            actionFn: (row) => nameAction(row.agent, SortableTableType.Agent),
        },
        {
            name: 'Agency',
            extractorFn: (row) => row.top_agency,
            actionFn: (row) => nameAction(row.top_agency, SortableTableType.Agency),
        },
        {
            name: 'Average List / Sale Price Differential',
            extractorFn: (row) => '$' + fmt_num(row.list_2_sale_diff),
            sortFn: (a, b) => fix_cmp(a.list_2_sale_diff, b.list_2_sale_diff, 'N/A'),
            classFn: (row) => fmt_diff_style(row.list_2_sale_diff, 0)
        },
        {
            name: 'Average Sale Price',
            extractorFn: (row) => '$' + fmt_num(row.sale_price),
            sortFn: (a, b) => fix_cmp(a.sale_price, b.sale_price, 'N/A'),
        },
        {
            name: 'Average Land Size',
            extractorFn: (row) => <span>{row.avg_land_area} {appConfig.AREA_UNIT_ELE}</span>,
            sortFn: (a, b) => fix_cmp(a.avg_land_area, b.avg_land_area, 'N/A'),
        },
        {
            name: 'Average $/' + appConfig.AREA_UNIT_STR,
            extractorFn: (row) => '$' + fmt_num(row.PParea) + '/' + appConfig.AREA_UNIT_STR,
            sortFn: (a, b) => fix_cmp(a.PParea, b.PParea, 'N/A'),
        },
        {
            name: 'Average Days on Market',
            extractorFn: (row) => row.days_on_market + ' days',
            sortFn: (a, b) => fix_cmp(a.days_on_market, b.days_on_market, 'N/A'),
        },
        {
            name: 'Number of Sold Properties',
            extractorFn: (row) => row.sold_properties,
            sortFn: (a, b) => fix_cmp(a.sold_properties, b.sold_properties, 'N/A'),
        },
        {
            name: '% withdrawn',
            extractorFn: (row) => row.ratio_withdrawn,
            sortFn: (a, b) => fix_cmp(a.ratio_withdrawn, b.ratio_withdrawn, 'N/A'),
        },
    ];

    const data: SortableTableData<CorelogicAgentData>[] = rankings.map(ranking => {
        return { row: ranking };
    });

    // Create market averages ranking

    const marketRanking: CorelogicAgentData = {
        agent: 'Market Averages',
        top_agency: ' - ',
        days_on_market: averages.days_on_market,
        last_ad_price: averages.last_ad_price,
        sale_price: averages.sale_price,
		list_2_sale_diff: averages.list_2_sale_diff,
        price_SQM: averages.price_SQM,
        PParea: averages.PParea,
        avg_land_area: averages.avg_land_area,
        sold_properties_per_agency: averages.sold_properties_per_agency,
        sold_properties_per_agent: averages.sold_properties_per_agent,
        sold_properties: averages.sold_properties_per_agent,
		score: '0', // we put 0 here because it is a neutral score
        rank: 0, // ignore
		ratio_withdrawn: averages.ratio_withdrawn
    };

    data.push({
        row: marketRanking,
        rowClassFn: () => 'highlight',
        cancelHeaderActions: true,
    });

    return <SortableTable headers={headers} data={data} />;
}

function AgencyRankings({ averages, rankings, nameAction }: AgencyRankingsProps) {
    const headers: SortableTableHeader<CorelogicAgencyData>[] = [
        {
            name: 'Index',
            extractorFn: (row) => row.score,
            sortFn: (a, b) => fix_cmp(a.score, b.score, '-'),
        },
        {
            name: 'Agency',
            extractorFn: (row) => row.agency,
            actionFn: (row) => nameAction(row.agency),
        },
        {
            name: 'Average List / Sale Price Differential',
            extractorFn: (row) => '$' + fmt_num(fmt_sale_list_price_diff(row.sale_price, row.last_ad_price)),
            sortFn: (a, b) => fix_cmp(
                fmt_sale_list_price_diff(a.sale_price, a.last_ad_price),
                fmt_sale_list_price_diff(b.sale_price, b.last_ad_price),
                'N/A'),
            classFn: (row) => fmt_diff_style(row.sale_price, row.last_ad_price)
        },
        {
            name: 'Average Sale Price',
            extractorFn: (row) => '$' + fmt_num(row.sale_price),
            sortFn: (a, b) => fix_cmp(a.sale_price, b.sale_price, 'N/A'),
        },
        {
            name: 'Average Land Size',
			extractorFn: (row) => <span>{row.avg_land_area} {appConfig.AREA_UNIT_ELE}</span>,
            sortFn: (a, b) => fix_cmp(a.avg_land_area, b.avg_land_area, 'N/A'),
        },
        {
            name: 'Average $/' + appConfig.AREA_UNIT_STR,
			extractorFn: (row) => '$' + fmt_num(row.PParea) + '/' + appConfig.AREA_UNIT_STR,
            sortFn: (a, b) => fix_cmp(a.PParea, b.PParea, 'N/A'),
        },
        {
            name: 'Average Days on Market',
            extractorFn: (row) => row.days_on_market + ' days',
            sortFn: (a, b) => fix_cmp(a.days_on_market, b.days_on_market, 'N/A'),
        },
        {
            name: 'Number of Sold Properties',
            extractorFn: (row) => row.sold_properties,
            sortFn: (a, b) => fix_cmp(a.sold_properties, b.sold_properties, 'N/A'),
        },
        {
            name: '% withdrawn',
            extractorFn: (row) => row.ratio_withdrawn,
            sortFn: (a, b) => fix_cmp(a.ratio_withdrawn, b.ratio_withdrawn, 'N/A'),
        },
    ];

    const data: SortableTableData<CorelogicAgencyData>[] = rankings.map(ranking => {
        return { row: ranking };
    });

    // Create market averages ranking

    const marketRanking: CorelogicAgencyData = {
        agency: 'Market Averages',
        days_on_market: averages.days_on_market,
        last_ad_price: averages.last_ad_price,
        sale_price: averages.sale_price,
		price_SQM: averages.price_SQM,
        PParea: averages.PParea,
        avg_land_area: averages.avg_land_area,
        sold_properties_per_agency: averages.sold_properties_per_agency,
        sold_properties_per_agent: averages.sold_properties_per_agent,
        sold_properties: averages.sold_properties_per_agency,
        rank: 0, // ignore
		score: '0',
		ratio_withdrawn: averages.ratio_withdrawn
    };

    data.push({
        row: marketRanking,
        rowClassFn: () => 'highlight',
        cancelHeaderActions: true,
    });

    return <SortableTable headers={headers} data={data} />;
}

function AgentTransactionHistory({ name, address, transactionDetails, doForcedSearch }: AgentTransactionHistoryProps) {
    const [apiLoading, setApiLoading] = useState(false);

    const initialDomainTx = { transactions: ([] as DomainAgentTransaction[]) } as DomainAgentTransactionsData;
    const [domainTx, setDomainTx] = useState(initialDomainTx);
    const [domainTxError, setDomainTxError] = useState('');
    const [domainTxDisplayLimit, setDomainTxDisplayLimit] = useState(25);

    // Convert to lowercase to avoid case mismatches
    const corelogicTx = transactionDetails.filter(t => t.agent.toLocaleLowerCase() === name.toLocaleLowerCase());

    // Fetch domain tx history
    useEffect(() => {
        const fullTxHistoryEndpoint = `/api/agent-transactions?name=${name}&state=${address.attributes.state}`;
        setApiLoading(true);
        setDomainTxError('');
        setDomainTx(initialDomainTx);
        setDomainTxDisplayLimit(25);

        fetch(fullTxHistoryEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch request failed with status : ' + response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if (json.error) {
                    throw new Error(json.error);
                } else {
                    setApiLoading(false);
                    setDomainTx(json);
                }
            })
            .catch(err => {
                setApiLoading(false);
                setDomainTxError(err.message);
            });
    }, [name]);

    const corelogicHeaders: SortableTableHeader<CorelogicTransactionData>[] = [
        {
            name: 'Property Type',
            extractorFn: (row) => fmt_capitalization(row.property_type),
        },
        {
            name: 'Address',
            extractorFn: (row) => row.address,
            actionFn: (row) => doForcedSearch(row.address),
            colSpan: 2,
        },
        {
            name: 'Sale Date',
            extractorFn: (row) => row.date,
            sortFn: (a, b) => fix_cmp(a.date, b.date, 'N/A'),
        },
        {
            name: 'List / Sale Price Differential',
            extractorFn: (row) => '$' + fmt_num(fmt_sale_list_price_diff(row.sale_price, row.list_price)),
            classFn: (row) => fmt_diff_style(row.sale_price, row.list_price),
            sortFn: (a, b) => fix_cmp(
                fmt_sale_list_price_diff(a.sale_price, a.list_price),
                fmt_sale_list_price_diff(b.sale_price, b.list_price),
                'N/A'),
        },
        {
            name: 'List Price Range',
            extractorFn: (row) => '$' + fmt_num(row.list_price_from) + ' - $' + fmt_num(row.list_price_to),
            sortFn: (a, b) => fix_cmp(a.list_price_to, b.list_price_to, 'N/A'),
            colSpan: 2,
        },
        {
            name: 'Sale Price',
            extractorFn: (row) => '$' + fmt_num(row.sale_price),
            sortFn: (a, b) => fix_cmp(a.sale_price, b.sale_price, 'N/A'),
        },
        {
            name: 'Land Size',
            extractorFn: (row) => <span>{row.land_area} {appConfig.AREA_UNIT_ELE}</span>,
            sortFn: (a, b) => fix_cmp(a.land_area, b.land_area, 'N/A'),
        },
        {
            name: '$/' + appConfig.AREA_UNIT_STR,
            extractorFn: (row) => '$' + fmt_num(row.PParea),
			sortFn: (a, b) => fix_cmp(a.PParea, b.PParea, 'N/A'),
        },
        {
            name: 'Days on Market',
            extractorFn: (row) => row.days_on_market + ' days',
            sortFn: (a, b) => fix_cmp(a.days_on_market, b.days_on_market, 'N/A'),
        },
    ];

    const corelogicData: SortableTableData<CorelogicTransactionData>[] = corelogicTx.map(tx => {
        return { row: tx };
    });

    const domainHeaders: SortableTableHeader<DomainAgentTransaction>[] = [
        {
            name: 'Address',
            extractorFn: (row) => row.address,
            actionFn: (row) => doForcedSearch(row.address),
            colSpan: 3,
        },
        {
            name: 'Sale Date',
            extractorFn: (row) => (row.sold_date !== 'N/A' ? row.sold_date : 'WITHDRAWN'),
            sortFn: (a, b) => fix_cmp(a.sold_date, b.sold_date, 'N/A'),
        },
        {
            name: 'List / Sale Price Differential',
            extractorFn: (row) => '$' + fmt_num(fmt_sale_list_price_diff(row.sale_price, row.last_ad_price)),
            classFn: (row) => fmt_diff_style(row.sale_price, row.last_ad_price),
            sortFn: (a, b) => fix_cmp(
                fmt_sale_list_price_diff(a.sale_price, a.last_ad_price),
                fmt_sale_list_price_diff(b.sale_price, b.last_ad_price),
                'N/A'),
        },
        {
            name: 'List Price Range',
            extractorFn: (row) => '$' + fmt_num(row.last_ad_price) + ' - $' + fmt_num(row.first_ad_price),
            sortFn: (a, b) => fix_cmp(a.first_ad_price, b.first_ad_price, 'N/A'),
            colSpan: 2,
        },
        {
            name: 'Sale Price',
            extractorFn: (row) => '$' + fmt_num(row.sale_price),
            sortFn: (a, b) => fix_cmp(a.sale_price, b.sale_price, 'N/A'),
        },
        {
            name: 'Land Size',
			extractorFn: (row) => <span>{row.land_area} {appConfig.AREA_UNIT_ELE}</span>,
            sortFn: (a, b) => fix_cmp(a.land_area, b.land_area, 'N/A'),
        },
        {
            name: '$/' + appConfig.AREA_UNIT_STR,
            extractorFn: (row) => '$' + fmt_num(fmt_price_per_sqm(row.sale_price, row.land_area)),
            sortFn: (a, b) => fix_cmp(
                fmt_price_per_sqm(a.sale_price, a.land_area),
                fmt_price_per_sqm(b.sale_price, b.land_area),
                'N/A'),
        },
        {
            name: 'Days on Market',
            extractorFn: (row) => row.days_on_market + ' days',
            sortFn: (a, b) => fix_cmp(a.days_on_market, b.days_on_market, 'N/A'),
        },
    ];

    // TODO(Vivek): refactor, instead use offset / limit in API call
    const domainDateCompare = (a: DomainAgentTransaction, b: DomainAgentTransaction) => {
        if (a.sold_date === 'N/A') {
            return 1;
        } else if (b.sold_date === 'N/A') {
            return -1;
        } else if (a.sold_date !== b.sold_date) {
            return a.sold_date < b.sold_date ? 1 : -1;
        } else {
            return a.address.localeCompare(b.address);
        }
    };

    const domainData: SortableTableData<DomainAgentTransaction>[] = domainTx
        .transactions
        .sort(domainDateCompare)
        .slice(0, domainTxDisplayLimit)
        .map(tx => {
            return { row: tx };
        });

    // TODO(Vivek): Should be done on backend instead?
    const txCountTotal: {
        countDaysOnMarket: number,
        totalDaysOnMarket: number,
        countFirstAdPrice: number,
        totalFirstAdPrice: number,
        countLastAdPrice: number,
        totalLastAdPrice: number,
        countLandArea: number,
        totalLandArea: number,
        countSalePrice: number,
        totalSalePrice: number,
    } = domainData
        .map(data => data.row)
        .reduce((prev, curr) => {
            // Ignore withdrawn properties
            if (curr.sold_date === 'N/A') {
                return prev;
            }

            if (!isNaN(curr.days_on_market)) {
                prev.totalDaysOnMarket += curr.days_on_market;
                prev.countDaysOnMarket += 1;
            }
            if (!isNaN(curr.last_ad_price)) {
                prev.totalLastAdPrice += curr.last_ad_price;
                prev.countLastAdPrice += 1;
            }
            if (!isNaN(curr.land_area)) {
                prev.totalLandArea += curr.land_area;
                prev.countLandArea += 1;
            }
            if (!isNaN(curr.sale_price)) {
                prev.totalSalePrice += curr.sale_price;
                prev.countSalePrice += 1;
            }
            return prev;
        }, {
            countDaysOnMarket: 0,
            totalDaysOnMarket: 0,
            countFirstAdPrice: 0,
            totalFirstAdPrice: 0,
            countLastAdPrice: 0,
            totalLastAdPrice: 0,
            countLandArea: 0,
            totalLandArea: 0,
            countSalePrice: 0,
            totalSalePrice: 0,
        });

    const averageTx: DomainAgentTransaction = {
        address: 'Averages for displayed transactions',
        agency: '-',
        bathrooms: 0,
        bedrooms: 0,
        car_spaces: 0,
        date_listed: '-',
        days_on_market: Math.round(txCountTotal.totalDaysOnMarket / txCountTotal.countDaysOnMarket),
        first_ad_agency: '-',
        first_ad_date: '-',
        first_ad_price: 0,
        last_ad_agency: '-',
        last_ad_date: '-',
        last_ad_price: Math.round(txCountTotal.totalLastAdPrice / txCountTotal.countLastAdPrice),
        land_area: Math.round(txCountTotal.totalLandArea / txCountTotal.countLandArea),
        price_listed: '-',
        sale_price: Math.round(txCountTotal.totalSalePrice / txCountTotal.countSalePrice),
        sold_date: '-',
    };

    domainData.push({
        row: averageTx,
        rowClassFn: () => 'highlight',
        cancelHeaderActions: true,
        overrideValues: { 'List Price Range': '-' },
    });

    const ref = useRef<HTMLElement>(null);
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [name]);

    return (
        <section ref={ref}>
            <h2>Transaction History For&nbsp;
		{name}&nbsp;
                {corelogicTx.length > 0 && '(' + corelogicTx[0].agency + ')'}
            </h2>
            <div className="column">
                <h3>Similar To Your Search</h3>
                <a href={`api/transactions-csv?agent=${name}`} className="right-align">💾</a>
            </div>
            <SortableTable headers={corelogicHeaders} data={corelogicData} />
            <h3>Full Transaction History</h3>
            <p className="error-message">{domainTxError}</p>
            {apiLoading
                ? <Loader loadingMessage="Loading..." />
                : <React.Fragment>
                    <SortableTable headers={domainHeaders} data={domainData} />
                    <button
                        disabled={domainTxDisplayLimit > domainTx.transactions.length}
                        onClick={() => setDomainTxDisplayLimit(domainTxDisplayLimit + 25)}>
                        Load More Transactions
		</button>
                    <p className="text-slight">
                        Currently displaying {Math.min(domainTxDisplayLimit, domainTx.transactions.length)}/
		    {domainTx.transactions.length} transactions
		</p>
			<AgentTransactionSummary 
				domainAgentTx={domainTx}
			/>
                </React.Fragment>}
        </section>
    );
}

function AgencyTransactionHistory({
    name,
    transactionDetails,
    doForcedSearch,
    nameAction }: AgencyTransactionHistoryProps) {
    // Convert to lowercase to avoid case mismatches
    const corelogicTx = transactionDetails.filter(t => t.agency.toLocaleLowerCase() === name.toLocaleLowerCase());

    const corelogicHeaders: SortableTableHeader<CorelogicTransactionData>[] = [
        {
            name: 'Agent',
            extractorFn: (row) => fmt_capitalization(row.agent),
            actionFn: (row) => nameAction(row.agent),
        },
        {
            name: 'Property Type',
            extractorFn: (row) => fmt_capitalization(row.property_type),
        },
        {
            name: 'Address',
            extractorFn: (row) => row.address,
            actionFn: (row) => doForcedSearch(row.address),
            colSpan: 2,
        },
        {
            name: 'Sale Date',
            extractorFn: (row) => row.date,
            sortFn: (a, b) => fix_cmp(a.date, b.date, 'N/A'),
        },
        {
            name: 'List / Sale Price Differential',
            extractorFn: (row) => '$' + fmt_num(fmt_sale_list_price_diff(row.sale_price, row.list_price)),
            classFn: (row) => fmt_diff_style(row.sale_price, row.list_price),
            sortFn: (a, b) => fix_cmp(
                fmt_sale_list_price_diff(a.sale_price, a.list_price),
                fmt_sale_list_price_diff(b.sale_price, b.list_price),
                'N/A'),
        },
        {
            name: 'List Price Range',
            extractorFn: (row) => '$' + fmt_num(row.list_price_from) + ' - $' + fmt_num(row.list_price_to),
            sortFn: (a, b) => fix_cmp(a.list_price_to, b.list_price_to, 'N/A'),
            colSpan: 2,
        },
        {
            name: 'Sale Price',
            extractorFn: (row) => '$' + fmt_num(row.sale_price),
            sortFn: (a, b) => fix_cmp(a.sale_price, b.sale_price, 'N/A'),
        },
        {
            name: 'Land Size',
			extractorFn: (row) => <span>{row.land_area} {appConfig.AREA_UNIT_ELE}</span>,
            sortFn: (a, b) => fix_cmp(a.land_area, b.land_area, 'N/A'),
        },
        {
            name: '$/' + appConfig.AREA_UNIT_STR,
            extractorFn: (row) => '$' + fmt_num(row.PParea),
			sortFn: (a, b) => fix_cmp(a.PParea, b.PParea, 'N/A'),
        },
        {
            name: 'Days on Market',
            extractorFn: (row) => row.days_on_market + ' days',
            sortFn: (a, b) => fix_cmp(a.days_on_market, b.days_on_market, 'N/A'),
        },
    ];

    const corelogicData: SortableTableData<CorelogicTransactionData>[] = corelogicTx.map(tx => {
        return { row: tx };
    });

    const ref = useRef<HTMLElement>(null);
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [name]);

    return (
        <section ref={ref}>
            <h2>Transaction History For {name}</h2>
            <h3>Similar To Your Search</h3>
            <SortableTable headers={corelogicHeaders} data={corelogicData} />
        </section>
    );
}

export interface AgentTransactionSummaryProps {
	domainAgentTx: DomainAgentTransactionsData;
}

function AgentTransactionSummary( {domainAgentTx}: AgentTransactionSummaryProps ) {
	if ('agent' in domainAgentTx) {
		return (
			<React.Fragment>
				<h3>Full Transaction History Summary</h3>
				<table>
					<thead>
						<tr>
							<th scope="col">Agent Name</th>
							<th scope="col">% Withdrawn</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{domainAgentTx.agent.name}</td>
							<td>{domainAgentTx.ratio_withdrawn}</td>
						</tr>
					</tbody>
				</table>
			</React.Fragment>
			)
	}
	else { return ( <React.Fragment /> ) }
}
