import React, { useState, useEffect, useRef } from 'react';

export function Loader({ loadingMessage }: { loadingMessage: string }) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, [loadingMessage]);
    return (
        <div ref={ref} style={{ paddingBottom: '10px' }}>
            <div id="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>{loadingMessage}</p>
        </div >
    );
}

export function DynamicLoader({ endpoint }: { endpoint: string }) {
    const [loadingMessage, setLoadingMessage] = useState('Starting...');

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(endpoint, { credentials: 'same-origin' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Fetch request failed with status: ' + response.statusText);
                    }
                    return response.json();
                })
                .then(json => setLoadingMessage(json.message))
                .catch(err => setLoadingMessage(err.message));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Loader loadingMessage={loadingMessage} />
    );

}
