import React, {useEffect, useState} from 'react';

// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export function About() {
    const [open, setOpen] = React.useState(false);
    const [backendVersion, setBackendVersion] = useState('???')

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault() // probably useless as there is no default action
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

	// Load the version number
	useEffect(() => {
        const versionEndpoint = '/api/version'
        fetch(versionEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch request failed with status : ' + response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if (json.error) {
                    throw new Error(json.error);
                } else {
					setBackendVersion(json.version);
                }
            })
            .catch(err => {
				setBackendVersion("Issues retrieving backend version.");
            });
    })

  const action = (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
  );

  const msg = `Rankagent ${backendVersion}.
Frontend v${process.env.REACT_APP_VERSION}.
Created by QuantPropertySolutions
`

      //  <Button onClick={handleClick}>About</Button>
  return (
    <div>
      <p onClick={handleClick}>{backendVersion}</p>
      <Snackbar
        anchorOrigin={{'vertical': 'top', 'horizontal': 'left'}}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </div>
  );
}
