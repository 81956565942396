import React from 'react';
import { AddressDetailsProps } from '../types';
import { appConfig } from '../uiconfig'
import { fmt_num, fmt_capitalization, fmt_sale_list_price_diff, fmt_diff_style } from '../utils';

export default function AddressDetails({ address }: AddressDetailsProps) {
    return (
        <React.Fragment>
            <table>
                <thead>
                    <tr>
                        <th scope="col">Property Type</th>
                        <th scope="col"># Bedrooms</th>
                        <th scope="col"># Bathrooms</th>
                        <th scope="col"># Car Spaces</th>
                        <th scope="col">Land Size</th>
                        <th scope="col">Floor Size</th>
                        <th scope="col">Last Sold</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{fmt_capitalization(address.attributes.property_type)}</td>
                        <td>{address.attributes.bedrooms}</td>
                        <td>{address.attributes.bathrooms}</td>
                        <td>{address.attributes.carSpaces}</td>
                        <td>{address.attributes.landArea} {appConfig.AREA_UNIT_ELE}</td>
						<td>{address.attributes.floorArea} {appConfig.AREA_UNIT_ELE}</td>
                        <td>{address.attributes.last_sold}</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th scope="col" colSpan={2}>List Price Range</th>
                        <th scope="col">Sale Price</th>
                        <th scope="col">List / Sale Price Differential</th>
                        <th scope="col">$ per {appConfig.AREA_UNIT_STR}</th>
                        <th scope="col">Days on Market</th>
                        <th scope="col">Sales Agent</th>
                        <th scope="col">Agency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            ${fmt_num(address.attributes.list_price_from)} -
			    ${fmt_num(address.attributes.list_price_to)}
                        </td>
                        <td>${fmt_num(address.attributes.sale_price)}</td>
                        <td className={fmt_diff_style(
                            Number(address.attributes.sale_price),
                            Number(address.attributes.list_price))}
                        >
                            ${fmt_num(fmt_sale_list_price_diff(
                                Number(address.attributes.sale_price),
                                Number(address.attributes.list_price)))}
                        </td>
                        <td>${fmt_num(address.attributes.PParea)}</td>
                        <td>{address.attributes.days_on_market} days</td>
                        <td>{address.attributes.agent}</td>
                        <td>{address.attributes.agency}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
}
