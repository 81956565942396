// AddressDeetsComparison
//
// This components shows the target property's attributes, such as number of bedrooms
// and bathrooms.
// It also handles calling the second provider, if available, for comparison.

import axios from 'axios'
import React, {useState, useMemo} from 'react';
import { Address, AddressDetailsProps } from '../types';
import AddressDetails from './AddressDetails';
import {Loader} from './Loader';
import { HAS_PROVIDER_2 } from '../uiconfig';

export default function AddressDeetsComparison({ address }: AddressDetailsProps) {

    const [errorMessage, setErrorMessage] = useState('');
    const [apiLoading, setApiLoading] = useState(false);
    const [addressComp, setAddressComp] = useState({} as Address);

	
	function onSubmit() {
        const validateAddressEndpoint = '/api/validate-address-2'
        setApiLoading(true);
        setErrorMessage('');
		axios.get(validateAddressEndpoint, { params: {
			address: address.address  // address
		}
			}).then(response => {
				const json  : any = response.data	
                if (json.error) {
                    throw new Error(json.error);
                } else {
                    const addressData: Address = {
                        address: json.one_line,
                        localityId: json.locality_id,
                        attributes: {
                            PParea: json.attributes.PParea ? json.attributes.PParea : 0,
                            agency: json.attributes.agency ? json.attributes.agency : '',
                            agent: json.attributes.agent ? json.attributes.agent : '',
                            bathrooms: json.attributes.bathrooms ? json.attributes.bathrooms : 0,
                            bedrooms: json.attributes.bedrooms ? json.attributes.bedrooms : 0,
                            carSpaces: json.attributes.carSpaces ? json.attributes.carSpaces : 0,
                            days_on_market: json.attributes.days_on_market ? json.attributes.days_on_market : 0,
                            landArea: json.attributes.landArea ? json.attributes.landArea : 0,
							floorArea: json.attributes.floorArea ? json.attributes.floorArea : 'N/A',
                            last_sold: json.attributes.last_sold ? json.attributes.last_sold : '',
                            list_price: json.attributes.list_price ? json.attributes.list_price : 0,
                            list_price_from: json.attributes.list_price_from ? json.attributes.list_price_from : 0,
                            list_price_to: json.attributes.list_price_to ? json.attributes.list_price_to : 0,
                            property_type: json.attributes.property_type ? json.attributes.property_type : '',
                            sale_price: json.attributes.sale_price ? json.attributes.sale_price : 0,
                            state: json.attributes.state ? json.attributes.state : '',
                        }
                    };
                    setApiLoading(false);
					setAddressComp(addressData);
                }
            })
            .catch(err => {
                setErrorMessage(err.message);
                setApiLoading(false);
            });
    }

	// not all backends support the comparison provider
	var provider2UI = null;
	if (HAS_PROVIDER_2) {
		provider2UI = (
		<React.Fragment>
			<span style={{display: "inline"}}>
			Looking for a second opinion? You can also request this data from a different provider -> 
			<button onClick={onSubmit}>Compare this data &#x1F50E; </button>
		</span>
		</React.Fragment>
		)
	}

	const loader = useMemo(
		() => apiLoading && <Loader loadingMessage="Validating address..." />,
		[apiLoading])

	const comparisonTable = useMemo(
		() => addressComp.address && <AddressDetails address={addressComp}/>,
		[addressComp])
    return (
        <section>
            <h2>Address Details</h2>
            <AddressDetails address={address} />
			{provider2UI}
			{loader}
			{comparisonTable}
            <p className="error-message">{errorMessage}</p>
		</section>
	)
}
