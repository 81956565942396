import React, { useEffect, useState } from 'react';
import { AnalyticsProps, SuburbAverages, CorelogicData } from '../types';
import MarketAverages from './MarketAverages';
import Rankings from './Rankings';

export default function Analytics({
    address,
    propertyDetails,
    refresh,
    notifyComplete,
    doForcedSearch }: AnalyticsProps) {
    const [suburbAverages, setSuburbAverages] = useState({} as SuburbAverages);
    const [rankings, setRankings] = useState({
        'agent_rankings': [],
        'agency_rankings': [],
        'transaction_details': []
    } as CorelogicData);
    const [marketAveragesErrorMessage, setMarketAveragesErrorMessage] = useState('');
    const [rankingsErrorMessage, setRankingsErrorMessage] = useState('');

    useEffect(() => {
        const marketAveragesEndpoint = '/api/analytics/market-averages';
        const rankingsEndpoint = '/api/analytics/rankings';

        if (refresh) {
            const marketFetch = fetch(marketAveragesEndpoint, { credentials: 'same-origin' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Fetch request failed with status : ' + response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    if (json.message && json.message.type === 'error') {
                        throw new Error(json.message.message);
                    } else {
                        setMarketAveragesErrorMessage('');
                        setSuburbAverages(json['suburb_averages']);
                    }
                })
                .catch(err => setMarketAveragesErrorMessage(err.message));
            const rankingsFetch = fetch(rankingsEndpoint, { credentials: 'same-origin' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Fetch request failed with status : ' + response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    if (json.message && json.message.type === 'error') {
                        throw new Error(json.message.message);
                    } else {
                        setRankingsErrorMessage('');
                        setRankings(json['rankings']);
                    }
                })
                .catch(err => setRankingsErrorMessage(err.message));
            Promise.all([marketFetch, rankingsFetch]).then(() => notifyComplete());
        }
    }, [refresh]);

    return (
        <React.Fragment>
            <MarketAverages
                propertyDetails={propertyDetails}
                averages={suburbAverages}
                errorMessage={marketAveragesErrorMessage} />
            <Rankings
                address={address}
                averages={suburbAverages}
                rankings={rankings}
                errorMessage={rankingsErrorMessage}
                doForcedSearch={doForcedSearch}
            />
        </React.Fragment>
    );
}
