export function fmt_num(num: string | number): string {
    if (typeof num === 'number') {
        return num.toLocaleString();
    } else {
        if (!isNaN(Number(num))) {
            return Number(num).toLocaleString();
        } else {
            return num;
        }
    }
}

export function fmt_capitalization(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function fmt_price_per_sqm(salePrice: number, landArea: number) {
    if (!isNaN(salePrice) && !isNaN(landArea)) {
        return Math.round(salePrice / landArea);
    } else {
        return 'N/A';
    }
}

export function fmt_sale_list_price_diff(salePrice: number, listPrice: number) {
    if (!isNaN(salePrice) && !isNaN(listPrice)) { // Can potentially be "NaN"
        return salePrice - listPrice;
    } else {
        return 'N/A';
    }
}

export function fmt_diff_style(a: number, b: number) {
    if (!isNaN(a) && !isNaN(b)) {
        if (a - b >= 0) {
            return 'text-green';
        } else {
            return 'text-light-red';
        }
    } else {
        return '';
    }
}

export function fix_cmp(a: any, b: any, nullvalue: any, cmp?: (a: any, b: any) => boolean) {
    if (a === nullvalue) {
        return false;
    } else if (b === nullvalue) {
        return true;
    } else if (cmp) {
        return cmp(a, b);
    } else {
        return a >= b;
    }
}
