import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './assets/css/normalize.css';
import './fonts.css';
import { Login } from './Login';
import Analytics from './sections/Analytics';
import ConfirmAddress from './sections/ConfirmAddress';
import PropertyDetails from './sections/PropertyDetails';
import Search from './sections/Search';
import { Address, SearchPropertyDetails, SectionTypes } from './types';

export default function App() {
    const storage = new Storage('1.04'); // Bump version whenever a change is made to the Storage class
    const [externalDepsLoaded, setExternalDepsLoaded] = useState(false);
    const [currentSection, setCurrentSection] = useState(storage.currentSection);
    const [address, setAddress] = useState(storage.address);
    const [propertyDetails, setPropertyDetails] = useState(storage.propertyDetails);
    const [refreshAnalytics, setRefreshAnalytics] = useState(true);
    const [emailLogin, setEmailLogin] = useState(storage.emailLogin);
    const [loggedIn, setLoggedIn] = useState(false);
    const [forcedSearch, setForcedSearch] = useState('');

    function clearState() {
        storage.clear();
        setCurrentSection(storage.currentSection);
        setAddress(storage.address);
        setPropertyDetails(storage.propertyDetails);
        setEmailLogin(storage.emailLogin);
        setLoggedIn(false);
    }

    function resetSearch() {
        setCurrentSection(SectionTypes.Search);
        storage.currentSection = SectionTypes.Search;
    }

    const nextSection = (section: SectionTypes): () => void => {
        return () => {
            storage.currentSection = section + 1;
			// this is needed in case the user manully scrolls to a previous sections
			// in which case the `currentSection` variable has not been updated.
			if(currentSection !== section) { setCurrentSection(section)	}
            setCurrentSection(section + 1);
        };
    };

    const searchComplete = (address: Address): void => {
        storage.address = address;
        setAddress(address);
        nextSection(SectionTypes.Search)();
    };

    const propertyDetailsComplete = (propertyDetails: SearchPropertyDetails): void => {
        storage.propertyDetails = propertyDetails;
        setPropertyDetails(propertyDetails);
        setRefreshAnalytics(true);
        nextSection(SectionTypes.PropertyDetails)();
    };

    const analyticsComplete = (): void => {
        setRefreshAnalytics(false);
    };

    const confirmAddressAnchorRef = useRef<HTMLDivElement>(null);
    const propertyDetailsAnchorRef = useRef<HTMLDivElement>(null);
    const analyticsAnchorRef = useRef<HTMLDivElement>(null);
    // TODO(Manu): Would probably be better to use a ref on the script tag
    // Anyway the only reason this is necessary is because React calls the effect twice
    // in strict mode when in deployment
    const scriptLoadingRef = useRef(false); // Use a ref to track if the gmaps lib is loading

    // Check if logged in
    useEffect(() => {
        const statusEndpoint = '/api/status';
        fetch(statusEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Not logged in');
                }
                setLoggedIn(true);
            })
            .catch(err => clearState());
    }, []);

    // Set up section transition scrolling effects
    useEffect(() => {
        if (currentSection === SectionTypes.ConfirmAddress) {
            confirmAddressAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else if (currentSection === SectionTypes.PropertyDetails) {
            propertyDetailsAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else if (currentSection === SectionTypes.Analytics && !refreshAnalytics) {
            analyticsAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentSection, refreshAnalytics]);

    // Load external dependencies: Google Maps
    useEffect(() => {
        const scriptId = 'google-maps-script';

        if (document.getElementById(scriptId) || scriptLoadingRef.current) {
            // already loading or loaded, nothing to do
            return;
        }

        scriptLoadingRef.current = true; // Set the script to loading
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCGN_LzAwS4Z3WApfZiiKxe7IieeL60JSo&libraries=places";
        script.async = true;
        script.defer = true;
        script.onerror = () => {
            console.error('Error loading Google Maps script.');
        };

        script.onload = () => {
            scriptLoadingRef.current = false
            setExternalDepsLoaded(true);
        };

        document.body.appendChild(script);

        return () => {
            const script = document.getElementById(scriptId);
            if (script) {
                document.body.removeChild(script);
            }
            };
    }, []);

    if (externalDepsLoaded) {
        if (loggedIn) {
            return (
                <React.Fragment>
                    <button className="floatingButton" onClick={resetSearch}>Reset</button>
                    {SectionTypes.Search <= currentSection
                        && <Search
                            notifyComplete={searchComplete}
                            completeLogout={clearState}
                            emailLogin={emailLogin}
							forcedSearch={forcedSearch}
                        />}
                    {SectionTypes.ConfirmAddress <= currentSection
                        && <React.Fragment>
                            <div ref={confirmAddressAnchorRef}></div>
                            <ConfirmAddress
                                address={address}
                                complete={SectionTypes.ConfirmAddress < currentSection}
                                notifyComplete={nextSection(SectionTypes.ConfirmAddress)} />
                        </React.Fragment>}
                    {SectionTypes.PropertyDetails <= currentSection
                        && <React.Fragment>
                            <div ref={propertyDetailsAnchorRef}></div>
                            <PropertyDetails
                                address={address}
                                complete={SectionTypes.PropertyDetails < currentSection}
                                notifyComplete={propertyDetailsComplete} />
                        </React.Fragment>}
                    {SectionTypes.Analytics <= currentSection
                        && <React.Fragment>
                            <div ref={analyticsAnchorRef}></div>
                            <Analytics
                                address={address}
                                propertyDetails={propertyDetails}
                                refresh={refreshAnalytics}
                                notifyComplete={analyticsComplete}
                                doForcedSearch={(address: string) => setForcedSearch(address)}
                            />
                        </React.Fragment>}
                </React.Fragment>
            );
        } else {
            return (
                <Login
                    completeLogin={(email: string) => {
                        storage.emailLogin = email;
                        setEmailLogin(email);
                        setLoggedIn(true);
                    }}
                />
            );
        }
    } else {
        return null;
    }
}

class Storage {
    private version: string;

    constructor(version: string) {
        this.version = version;
        // Reset localStorage and update version if version has changed, to avoid backwards compatibility issues
        const storageVersion = localStorage.getItem('version');
        if (!storageVersion || storageVersion !== version) {
            this.clear();
        }
    }

    clear(): void {
        localStorage.clear();
        localStorage.setItem('version', this.version);
    }

    get currentSection(): SectionTypes {
        return Number(localStorage.getItem('currentSection')) || SectionTypes.Search;
    }

    set currentSection(currentSection: SectionTypes) {
        localStorage.setItem('currentSection', currentSection.toString());
    }

    get emailLogin(): string {
        return localStorage.getItem('emailLogin') ?? '';
    }

    set emailLogin(email: string) {
        localStorage.setItem('emailLogin', email);
    }

    get address(): Address {
        return {
            address: localStorage.getItem('address') ?? '',
            localityId: localStorage.getItem('localityId') ?? '',
            attributes: {
                PParea: localStorage.getItem('PParea') ?? '',
                agency: localStorage.getItem('agency') ?? '',
                agent: localStorage.getItem('agent') ?? '',
                bathrooms: localStorage.getItem('bathrooms') ?? '',
                bedrooms: localStorage.getItem('bedrooms') ?? '',
                carSpaces: localStorage.getItem('carSpaces') ?? '',
                days_on_market: localStorage.getItem('days_on_market') ?? '',
                landArea: localStorage.getItem('landArea') ?? '',
                floorArea: localStorage.getItem('floorArea') ?? '',
                last_sold: localStorage.getItem('last_sold') ?? '',
                list_price: localStorage.getItem('list_price') ?? '',
                list_price_from: localStorage.getItem('list_price_from') ?? '',
                list_price_to: localStorage.getItem('list_price_to') ?? '',
                property_type: localStorage.getItem('property_type') ?? '',
                sale_price: localStorage.getItem('sale_price') ?? '',
                state: localStorage.getItem('state') ?? ''
            }
        };
    }

    set address(address: Address) {
        localStorage.setItem('address', address.address);
        localStorage.setItem('localityId', address.localityId);

        localStorage.setItem('PParea', address.attributes.PParea.toString());
        localStorage.setItem('agency', address.attributes.agency);
        localStorage.setItem('agent', address.attributes.agent);
        localStorage.setItem('bathrooms', address.attributes.bathrooms.toString());
        localStorage.setItem('bedrooms', address.attributes.bedrooms.toString());
        localStorage.setItem('carSpaces', address.attributes.carSpaces.toString());
        localStorage.setItem('days_on_market', address.attributes.days_on_market.toString());
        localStorage.setItem('landArea', address.attributes.landArea.toString());
        localStorage.setItem('last_sold', address.attributes.last_sold);
        localStorage.setItem('list_price', address.attributes.list_price.toString());
        localStorage.setItem('list_price_from', address.attributes.list_price_from.toString());
        localStorage.setItem('list_price_to', address.attributes.list_price_to.toString());
        localStorage.setItem('property_type', address.attributes.property_type);
        localStorage.setItem('sale_price', address.attributes.sale_price.toString());
        localStorage.setItem('state', address.attributes.state);
    }

    get propertyDetails(): SearchPropertyDetails {
        return {
            typeOfResidence: localStorage.getItem('typeOfResidence') ?? '',
            nBedrooms: localStorage.getItem('nBedrooms') ?? '',
            nBathrooms: localStorage.getItem('nBathrooms') ?? '',
            nCarspaces: localStorage.getItem('nCarspaces') ?? '',
            maxDistance: localStorage.getItem('maxDistance') ?? '',
            nMonths: localStorage.getItem('nMonths') ?? ''
        };
    }

    set propertyDetails(propertyDetails: SearchPropertyDetails) {
        localStorage.setItem('typeOfResidence', propertyDetails.typeOfResidence);
        localStorage.setItem('nBedrooms', propertyDetails.nBedrooms);
        localStorage.setItem('nBathrooms', propertyDetails.nBathrooms);
        localStorage.setItem('nCarspaces', propertyDetails.nCarspaces);
        localStorage.setItem('maxDistance', propertyDetails.maxDistance);
        localStorage.setItem('nMonths', propertyDetails.nMonths);
    }
}
