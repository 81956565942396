import React, { useState } from 'react';
import logo from './assets/images/logo.png';
import { LoginProps, LogoutProps } from './types';

export function Login({ completeLogin }: LoginProps) {
    const [errorMessage, setErrorMessage] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [resetEmail, setResetEmail] = useState<string>('');
    const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);

    const onChange = (setFn: typeof setEmail) => (e: any): void => {
        setFn(e.target.value);
    };

    const onSubmit = () => {
        setErrorMessage('');
        const loginEndpoint = '/api/login';
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('password', password);

        fetch(loginEndpoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Incorrect email or password');
                }
                completeLogin(email);
            })
            .catch(err => {
                setErrorMessage(err.message);
            });
    };

    const onResetPassword = () => {
        setErrorMessage('');
        const resetEndpoint = '/api/reset-password';
        const formData = new URLSearchParams();
        formData.append('email', resetEmail);

        fetch(resetEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.message || 'Failed to send reset email');
                    });
                }
                alert('Password reset email sent');
                setIsResettingPassword(false);
            })
            .catch(err => {
                setErrorMessage(err.message || 'An error occurred');
            });
    };

    return (
        <section id="login">
            <img className="logo" src={logo} alt="Logo" />
            {isResettingPassword ? (
                <div>
                    <label htmlFor="resetEmail">Enter your email to reset password: </label>
                    <input id="resetEmail" type="email" value={resetEmail} onChange={onChange(setResetEmail)} />
                    <button onClick={onResetPassword}>Reset Password</button>
                    <button onClick={() => setIsResettingPassword(false)}>Back to Login</button>
                </div>
            ) : (
                <div>
                    <div>
                        <label htmlFor="email">Email: </label>
                        <input id="email" type="text" value={email} onChange={onChange(setEmail)} />
                    </div>
                    <div>
                        <label htmlFor="password">Password: </label>
                        <input id="password" type="password" value={password} onChange={onChange(setPassword)} />
                    </div>
                    <button onClick={onSubmit}>Login</button>
                    <button onClick={() => setIsResettingPassword(true)}>Forgot Password?</button>
                </div>
            )}
            <p className="error-message">{errorMessage}</p>
        </section>
    );
}

export function Logout({ emailLogin, completeLogout }: LogoutProps) {
    function onLogout() {
        const logoutEndpoint = '/api/logout';
        fetch(logoutEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Could not log out');
                }
                completeLogout();
            })
            .catch(err => console.log(err)); // TODO(Vivek): not great...
    }
    return (
        <div id="logout">
            <p className="text-slight text-small">Logged in as {emailLogin}</p>
            <button onClick={onLogout} >Logout</button>
        </div >
    );
}
