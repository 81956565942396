import React, { useState, useRef, useEffect } from 'react';
import { SearchProps, Address } from '../types';
import logo from '../assets/images/logo.png';
import { Loader } from './Loader';
import { Logout } from '../Login';
import { About } from '../About';
import { GMAPS_COUNTRY } from '../uiconfig'

declare var google: any; // should have been loaded in App.tsx

export default function Search({ 
	notifyComplete, 
	emailLogin,
	completeLogout,
	forcedSearch }: SearchProps) {

    const [errorMessage, setErrorMessage] = useState('');
    const [apiLoading, setApiLoading] = useState(false);

    const [address, setAddress] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    function onSubmit(address: string) {
        const validateAddressEndpoint = '/api/validate-address?address=' + encodeURIComponent(address);
        setApiLoading(true);
        setErrorMessage('');
        fetch(validateAddressEndpoint, { credentials: 'same-origin' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch request failed with status : ' + response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if (json.error) {
                    throw new Error(json.error);
                } else {
                    const addressData: Address = {
                        address: json.address,
                        localityId: json.localityId,
                        attributes: {
                            PParea: json.attributes.PParea ? json.attributes.PParea : 0,
                            agency: json.attributes.agency ? json.attributes.agency : '',
                            agent: json.attributes.agent ? json.attributes.agent : '',
                            bathrooms: json.attributes.bathrooms ? json.attributes.bathrooms : 0,
                            bedrooms: json.attributes.bedrooms ? json.attributes.bedrooms : 0,
                            carSpaces: json.attributes.carSpaces ? json.attributes.carSpaces : 0,
                            days_on_market: json.attributes.days_on_market ? json.attributes.days_on_market : 0,
                            landArea: json.attributes.landArea ? json.attributes.landArea : 0,
                            floorArea: json.attributes.floorArea ? json.attributes.floorArea : 0,
                            last_sold: json.attributes.last_sold ? json.attributes.last_sold : '',
                            list_price: json.attributes.list_price ? json.attributes.list_price : 0,
                            list_price_from: json.attributes.list_price_from ? json.attributes.list_price_from : 0,
                            list_price_to: json.attributes.list_price_to ? json.attributes.list_price_to : 0,
                            property_type: json.attributes.property_type ? json.attributes.property_type : '',
                            sale_price: json.attributes.sale_price ? json.attributes.sale_price : 0,
                            state: json.attributes.state ? json.attributes.state : '',
                        }
                    };
                    setApiLoading(false);
                    notifyComplete(addressData);
                }
            })
            .catch(err => {
                setErrorMessage(err.message);
                setApiLoading(false);
            });
    }

    // Set up Google Maps autocomplete
    useEffect(() => {
        if (inputRef.current) {
            let options = {
                componentRestrictions: { country: GMAPS_COUNTRY } // Search limited to this country
            };

            const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);
            autocomplete.setFields(['formatted_address']);
            autocomplete.addListener('place_changed', () => {
                let formattedAddress: string = autocomplete.getPlace().formatted_address;
                // TODO(Vivek): Hacky... removes the country name from the address, since it breaks Corelogic
                formattedAddress = formattedAddress.slice(0, formattedAddress.lastIndexOf(','));
                setAddress(formattedAddress);
                onSubmit(formattedAddress);
            });
        }
    }, []);

    // Set up forced search
    useEffect(() => {
        if (forcedSearch) {
            setAddress(forcedSearch);
            onSubmit(forcedSearch);
        }
    }, [forcedSearch]);

	// TODO(Manu):
    return (
        <section className="search">
            <div className="about">
                <About />
                <Logout emailLogin={emailLogin} completeLogout={completeLogout} />
            </div>
            <img className="logo" src={logo} alt="Real Estate BTN logo" />
            <h2>Search An Address</h2>
            <input
                ref={inputRef}
                type="text"
                name="address"
                className="search text-input"
                placeholder="Enter Address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
            />
            <button className="search button" onClick={() => onSubmit(address)}>Search</button>
            <p className="error-message">{errorMessage}</p>
            {apiLoading && <Loader loadingMessage="Validating address..." />}
        </section>
    );
}
