import Slider from '@mui/material/Slider';
import React, { useState, useMemo } from 'react';
import { PropertyDetailsProps, SearchPropertyDetails } from '../types';
import AddressDeetsComparison from './AddressDeetsComparison';
import { DynamicLoader } from './Loader';
import {appConfig, typeOfResidenceValues, typesToResidenceValues} from '../uiconfig';

// TODO(Vivek): this component is too bulky. Simplify it.
export default function PropertyDetails({ address, complete, notifyComplete }: PropertyDetailsProps) {
    // Constants
    const timelineValues = ['3', '6', '12', '24', '36', '60'];
    const timelineLabels = ['3 months', '6 months', '12 months', '2 years', '3 years', '5 years'];

    // State definitions
    const [errorMessage, setErrorMessage] = useState('');
    const [apiLoading, setApiLoading] = useState(false);

    const [propertyStatus, setPropertyStatus] = useState('residential');
	const [typeOfResidence, setTypeOfResidence] = useState(
		typesToResidenceValues.residential[address.attributes.property_type]
	);
    const [nBedrooms, setNBedrooms] = useState(Math.min(Number(address.attributes.bedrooms), 5).toString());
    const [nBathrooms, setNBathrooms] = useState(Math.min(Number(address.attributes.bathrooms), 5).toString());
    const [nCarspaces, setNCarspaces] = useState(Math.min(Number(address.attributes.carSpaces), 3).toString());
    const [maxDistance, setMaxDistance] = useState(4);
    const [nMonths, setNMonths] = useState(2);
	const [pastDate, setPastDate] = useState(new Date());
	const [runInThePast, setRunInThePast] = useState(false);

    // Helper functions
    const onChange = (setFn: typeof setTypeOfResidence) => (e: any): void => {
        setFn(e.target.value);
    };

    const resetPropertyType = (e: any): void => {
        setPropertyStatus(e.target.value);
        setTypeOfResidence(e.target.value === 'commercial'
            ? 'retail'
            : 'house'
        );
    };

    const onSubmit = () => {
        setApiLoading(true);
        setErrorMessage('');

        const propertyTypes: string = propertyStatus === 'commercial'
            ? typeOfResidenceValues.commercial[typeOfResidence]
            : typeOfResidenceValues.residential[typeOfResidence];

		// pass this only if runInThePast is activated
		const startDateArg = runInThePast ? `&start_date=${formatDate(pastDate)}` : ''         
        // TODO(Vivek): simplify ; I should be able to automate creating the endpoint
		const propertyDetailsEndpoint = '/api/pull-address-data?'
            + `property_types=${propertyTypes}&`
            + `n_bedrooms=${nBedrooms}&`
            + `n_bathrooms=${nBathrooms}&`
            + `n_carspaces=${nCarspaces}&`
            + `max_distance=${appConfig.searchRadiiValues[maxDistance]}&`
			+ `n_months=${timelineValues[nMonths]}`
		    + startDateArg;


        const propertyDetails: SearchPropertyDetails = {
            typeOfResidence,
            nBedrooms,
            nBathrooms,
            nCarspaces,
            maxDistance: appConfig.searchRadiiLabels[maxDistance],
            nMonths: timelineLabels[nMonths]
        };

        fetch(propertyDetailsEndpoint, { credentials: 'same-origin' })
            .then(response => {
                // First, clone the response so we can read it twice if needed
                const responseClone = response.clone();
                
                return response.json()
                    .catch(() => {
                        // If JSON parsing fails, return the text content instead
                        return responseClone.text().then(textContent => {
                            return { error: `Non-JSON response: ${textContent}` };
                        });
                    })
                    .then(data => {
                        if (!response.ok) {
                            // If response is not ok, throw an error with the message from 'error' field or status
                            throw new Error(data.error || `Fetch request failed with status: ${response.status}`);
                        }
                        return data;
                    });
            })
            .then(data => {
                // This block will only run for successful responses
                setApiLoading(false);
                // if there are no errors we don't need to save the results
                // as all the important data is saved server side
                notifyComplete(propertyDetails);
            })
            .catch(err => {
                setErrorMessage(err.message);
                setApiLoading(false);
            });

    }
           
	const getLoaderCircle = useMemo(() => apiLoading && <DynamicLoader endpoint="/api/progress" />, [apiLoading])

    return (
        <React.Fragment>
            <AddressDeetsComparison address={address} />
            <section id="property-details">
                <h2>Search Property Details</h2>
                <h3>Type of residence</h3>
                <div>
                    <input
                        className="radio-btn"
                        type="radio"
                        name="property_status"
                        id="property_status_residential"
                        value="residential"
                        checked={propertyStatus === 'residential'}
                        onChange={resetPropertyType} />
                    <label htmlFor="property_status_residential">Residential</label>
                    <input
                        className="radio-btn"
                        type="radio"
                        name="property_status"
                        id="property_status_commercial"
                        value="commercial"
                        checked={propertyStatus === 'commercial'}
                        onChange={resetPropertyType} />
                    <label htmlFor="property_status_commercial">Commercial</label>
                </div>
                <div>
                    {propertyStatus === 'residential'
                        ? <select value={typeOfResidence} onChange={onChange(setTypeOfResidence)}>
                            <option value="house">House</option>
                            <option value="unit">Apartment/Unit</option>
                            <option value="townhouse">Townhouse</option>
                            <option value="land">Land</option>
                            <option value="acreage">Acreage</option>
                            <option value="rural">Rural</option>
                            <option value="block_of_units">Block of Units</option>
                            <option value="villa">Villa</option>
                            <option value="retirement">Retirement Living</option>
                            <option value="other">Other</option>
                        </select>
                        : <select value={typeOfResidence} onChange={onChange(setTypeOfResidence)}>
                            <option value="retail">Retail</option>
                            <option value="office">Offices</option>
                            <option value="industrial_and_warehouse">Industrial & Warehouse</option>
                            <option value="showroom_and_bulkygoods">Showrooms & Bulky Goods</option>
                            <option value="land_and_developments">Land & Developments</option>
                            <option value="hotel_and_leisure">Hotel & Leisure</option>
                            <option value="medical_and_consulting">Medical & Consulting</option>
                            <option value="commercial_farming">Commercial Farming</option>
                            <option value="other">Other</option>
                        </select>
                    }
                </div>
                <h3>Property Characteristics</h3>
                <div className="left-aligned">
                    <p className="form-header"># of bedrooms :</p>
                    <Slider
                        style={{ width: '60%', margin: '0' }}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(val: number) => val.toString()}
                        getAriaValueText={(val: number) => val.toString()}
                        marks={genMarks(['Studio', '1', '2', '3', '4', '5+'])}
                        min={0}
                        max={5}
                        step={null}
                        defaultValue={Number(nBedrooms)}
                        onChange={(e, val) => setNBedrooms(val.toString())}
                    />
                </div>
                <div className="left-aligned">
                    <p className="form-header"># of bathrooms:</p>
                    <Slider
                        style={{ width: '60%', margin: '0' }}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(val: number) => val.toString()}
                        getAriaValueText={(val: number) => val.toString()}
                        marks={genMarks(['None', '1', '2', '3', '4', '5+'])}
                        min={0}
                        max={5}
                        step={null}
                        defaultValue={Number(nBathrooms)}
                        onChange={(e, val) => setNBathrooms(val.toString())}
                    />
                </div>
                <div className="left-aligned">
                    <p className="form-header"># of car spaces:</p>
                    <Slider
                        style={{ width: '60%', margin: '0' }}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(val: number) => val.toString()}
                        getAriaValueText={(val: number) => val.toString()}
                        marks={genMarks(['None', '1', '2', '3', '4', '5+'])}
                        min={0}
                        max={5}
                        step={null}
                        defaultValue={Number(nCarspaces)}
                        onChange={(e, val) => setNCarspaces(val.toString())}
                    />
                </div>
                <div className="left-aligned">
                    <p className="form-header">Search Radius:</p>
                    <Slider
                        style={{ width: '60%', margin: '0' }}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="off"
                        getAriaValueText={(val: number) => appConfig.searchRadiiLabels[val]}
                        marks={genMarks(appConfig.searchRadiiLabels)}
                        min={0}
                        max={7}
                        step={null}
                        defaultValue={Number(maxDistance)}
                        onChange={(e, val) => setMaxDistance(val as number)}
                    />
                </div>
                <div className="left-aligned">
                    <p className="form-header">Timeline:</p>
                    <Slider
                        style={{ width: '60%', margin: '0' }}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="off"
                        getAriaValueText={(val: number) => timelineLabels[val]}
                        marks={genMarks(timelineLabels)}
                        min={0}
                        max={5}
                        step={null}
                        defaultValue={Number(nMonths)}
                        onChange={(e, val) => setNMonths(val as number)}
                    />
                </div>
				<div>
                    <input
                        type="checkbox"
                        name="run_in_past"
                        id="run_in_past"
                        value="false"
                        checked={runInThePast}
						onChange={(e) => setRunInThePast(e.target.checked)} />
                    <label htmlFor="run_in_the_past">Run in the past</label>
				</div>
				{ runInThePast &&
				<div>
					<label htmlFor="start_date">Start date for transactions: </label>
					<input
						type="date"
						id="start_date"
						value={formatDate(pastDate)} 
						onChange={(event) => setPastDate(new Date(event.target.value))}
					/>
                </div>
				}
                <button onClick={onSubmit}>{complete ? "Update" : "Submit"}</button>
                <p className="error-message">{errorMessage}</p>
				{getLoaderCircle}
            </section>
        </React.Fragment>
    );
}

/** Convert to YYYY-MM-DD format.
 * */
function formatDate(date: Date) {
	return (date.getFullYear() + "-" +  ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2) )
}

function genMarks(labels: string[]) {
    const marks: { value: number, label: string }[] = [];
    labels.forEach((label: string, i: number) => marks.push({ value: i, label }));

    return marks;
}
